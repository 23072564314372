import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    selectedCountryCode = document.getElementById('cart_invoiceData_country').value;
    static targets = ['itemList', 'summaryBox', 'invoiceForm'];
    static values = {
        itemListUrl: String,
        summaryBoxUrl: String,
    }

    connect() {
        this.toggleInvoiceForm();
        this.getItemList();
        this.getSummaryBox();
    }

    async getItemList() {
        this.itemListTarget.innerHTML = await $.get(this.itemListUrlValue);
    }

    async getSummaryBox() {
        const getInvoiceCheckbox = document.getElementById('cart_getInvoice');
        var url = this.summaryBoxUrlValue;
        if (this.selectedCountryCode && getInvoiceCheckbox !== null && getInvoiceCheckbox.checked) {
            url = url +'?invoiceCountryCode=' + this.selectedCountryCode
        }
        this.summaryBoxTarget.innerHTML = await $.get(url);
    }

    toggleInvoiceForm() {
        const getInvoiceCheckbox = document.getElementById('cart_getInvoice');
        if (getInvoiceCheckbox !== null && getInvoiceCheckbox.checked) {
            this.invoiceFormTarget.style.display = "block";
            this.invoiceFormTarget.querySelectorAll('input').forEach(input => input.required = true);
            this.invoiceFormTarget.querySelectorAll('select').forEach(select => select.required = true);
            this.invoiceFormTarget.querySelectorAll('label').forEach(label => label.classList.add('required'));
        } else {
            this.invoiceFormTarget.style.display = "none";
            this.invoiceFormTarget.querySelectorAll('input').forEach(input => input.required = false);
            this.invoiceFormTarget.querySelectorAll('select').forEach(select => select.required = false);
            this.invoiceFormTarget.querySelectorAll('label').forEach(label => label.classList.remove('required'));
        }

        this.getSummaryBox()
    }

    onCountryChange(event) {
        this.selectedCountryCode = event.currentTarget.value;
        this.getSummaryBox()
    }

    removeCartItem(event) {
        event.preventDefault();
        const url = event.target.dataset.removeUrl;
        var _this = this;
        _this.summaryBoxTarget.style.opacity = .5;
        _this.itemListTarget.style.opacity = .5;

        $.ajax({
            url: url,
            type: 'DELETE',
            success: function(result) {
                _this.itemListTarget.dataset.itemsLength = _this.itemListTarget.dataset.itemsLength - 1;
                
                if (parseInt(_this.itemListTarget.dataset.itemsLength) === 0) {
                    window.location.reload();
                } else {
                    _this.getItemList();
                    _this.getSummaryBox();
                    _this.summaryBoxTarget.style.opacity = 1;
                    _this.itemListTarget.style.opacity = 1;
                }
            }
        });
    }
}