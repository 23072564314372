import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import flatpickr from "flatpickr";
import { Polish } from "flatpickr/dist/l10n/pl.js"
import Splide from '@splidejs/splide';

export default class extends Controller {
    activePageDiv = null;
    activeNavigationElement = null;

    static targets = ['modal', 'modalBody', 'modalHeader', 'modalFooter', 'modalTitle', 'modalError', 'modalNavigation', 'elementToUpdate'];
    static values = {
        formUrl: String,
        closeModalUrl: { type: String, default: '' },
        canQuit: { type: Boolean, default: true },
        autoOpen: { type: Boolean, default: false },
        reloadPage: { type: Boolean, default: false },
        closeAfterSubmit: { type: Boolean, default: false },
        numberOfSteps: Number,
        cartServiceId: Number,
        isCart: { type: Boolean, default: false }
    }
    
    connect() {
        if (this.hasElementToUpdateTarget === true) {
            this.updateElements()
        } 
        if (this.autoOpenValue === true) {
            this.openModal()
        }   
    }

    async openModal(event) {
        if (event) {
            event.preventDefault();
            if (event.target.closest('.context_menu_list') !== null) {
                document.body.classList.toggle('modal_active')
                event.target.closest('.context_menu_list').classList.toggle('hovered')
            }
        }

        if (this.calendarController !== null) {
            document.body.classList.add('modal_opened');
        }
        
        this.modalBodyTarget.innerHTML = await $.ajax(this.formUrlValue);
        this.enableDatePickers();
        document.querySelectorAll('.checkboxes_frame').forEach(input => {
            input.addEventListener('click', this.checkFrameButton)
        })
        if (this.numberOfStepsValue > 0) {
            this.activePageDiv = document.getElementsByClassName('form_step active')[0]
            const navigationBody = this.modalBodyTarget.getElementsByClassName('modal_form_navigation');
            if (navigationBody.length < 0) {
                const activeNavigationElement = navigationBody[0].querySelectorAll("[data-step='1']")[0]
                this.setActiveNavigationElement(activeNavigationElement);
            }
        }

        if (document.querySelector('.select2entity') !== null) {
            $('script[src="' + '/bundles/tetranzselect2entity/js/select2entity.js' + '"]').remove();
            $('<script>').attr('src', '/bundles/tetranzselect2entity/js/select2entity.js').appendTo('head');
        }

        this.modalTarget.classList.add("showing"); 
    }
    
    async closeModal(event) {
        if (event) {
            event.preventDefault();
            if (event.target.closest('.context_menu_list') !== null) {
                document.body.classList.toggle('modal_active')
                event.target.closest('.context_menu_list').classList.toggle('hovered')
            }
        }

        if (this.canQuitValue === false) {
            if (this.hasModalNavigationTarget) {
                this.modalNavigationTarget.remove();
            }
            if (this.hasModalTitleTarget) {
                this.modalTitleTarget.innerHTML = '';
            }
            this.modalBodyTarget.style.opacity = .5;
            const response = await fetch(this.closeModalUrlValue);
            this.modalBodyTarget.innerHTML = await response.text();
            this.modalBodyTarget.style.opacity = 1;
            this.canQuitValue = true;
        } else {
            if (typeof event !== "undefined" && typeof event.target.dataset.url !== "undefined") {
                window.location.href = event.target.dataset.url;
            }
            if (this.calendarController !== null) {
                document.body.classList.remove('modal_opened');
            }

            if (this.hasModalNavigationTarget) {
                this.modalNavigationTarget.remove();
            }
            // if (this.hasModalTitleTarget) {
            //     this.modalTitleTarget.innerHTML = '';
            // }
            if (this.hasModalBodyTarget) {
                this.modalBodyTarget.innerHTML = '';
            }
            this.modalTarget.classList.remove("showing"); 
        }
    }

    submitWithEnter(event) {
        if (event.keyCode == 13) {
          this.submitForm(event)
        }
      }

    async submitForm(event) {
        if (this.activePageDiv !== null && !this.pageHtmlValidation()) {
            return false;
        }

        event.preventDefault();
        const $form = $(this.modalBodyTarget).find('form');
        var formData = new FormData($form[0]);
        
        try {
            let content = await $.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: formData,
                processData: false,
                contentType: false,
            });
            
            this.modalBodyTarget.style.opacity = 0.5;
            this.modalBodyTarget.innerHTML = content;

            if (this.hasModalErrorTarget && this.modalErrorTarget.innerHTML !== '') {
                    this.modalBodyTarget.style.opacity = 1;
            } else {
                if (this.closeAfterSubmitValue === true) {
                    if (this.hasElementToUpdateTarget === true) {
                        this.updateElements()
                    } 
                    
                    this.modalBodyTarget.style.opacity = 1;
                    this.closeModal()
                } else {
                    if (typeof content !== "undefined" && typeof content.closeRedirectUrl !== "undefined") {
                        this.modalTarget.querySelector('.close_modal').dataset.url = content.closeRedirectUrl;
                    }

                    if (event.target.hasAttribute('data-redirect-url')) {
                        this.newContent(event);
                    } else if (this.reloadPageValue === true) {
                        this.closeModal()
                        window.location.reload();
                    } else {
                        this.refreshContent()
                    }
                }
            }   
            
            if (event.target.dataset.canQuit === '1') {
                this.canQuitValue = true;
            }

            if (this.calendarController !== null) {
                await this.calendarController.getCalendar()
            }

            if (this.modalFormStepController !== null) {
                await this.modalFormStepController.getNavigation()
            }
            
        } catch (e) {
            this.modalBodyTarget.innerHTML = e.responseText;
            if (this.numberOfStepsValue > 0) {
                const errors = this.modalBodyTarget.querySelectorAll('.flash_error > ul');
                
                if (errors.length) {
                    this.activePageDiv = errors[0].parentNode.parentNode.parentNode;
                } 

                this.modalBodyTarget.getElementsByClassName('form_step active')[0].classList.remove('active');
                
                
                const navigationStep = [...this.activePageDiv.parentNode.querySelectorAll('.form_step')].indexOf(this.activePageDiv) + 1;
                if (this.modalBodyTarget.getElementsByClassName('modal_form_navigation').length) {
                    this.setActiveNavigationElement(this.modalBodyTarget
                        .getElementsByClassName('modal_form_navigation')[0]
                        .querySelectorAll("[data-step='"+ navigationStep +"']")[0]
                    );
                }

                this.activePageDiv.classList.add('active')
            }
            
            if (this.hasCartServiceIdValue && this.cartServiceIdValue > 0) {
                this.enableCartDatePicker()
            } 
        }
    }
    
    async refreshContent() {
        this.modalBodyTarget.style.opacity = .5;
        const response = await fetch(this.formUrlValue);
        this.modalBodyTarget.innerHTML = await response.text();
        this.modalBodyTarget.style.opacity = 1;
    }

    async newContent(event) {
        this.modalTarget.classList.add("showing"); 
        const url = event.target.dataset.redirectUrl;
        if (this.hasModalNavigationTarget) {
            this.modalNavigationTarget.remove();
        }
        
        this.modalBodyTarget.style.opacity = .5;
        const response = await fetch(url);
        if (this.hasModalTitleTarget) {
            this.modalTitleTarget.innerHTML = event.target.dataset.title ?? '';
        }
        var html =  await response.text();
        const closeButton = this.modalTarget.querySelector('.close_modal').cloneNode(true);
        closeButton.classList.add('secondary_close_modal', 'button_main', 'full_width_button');
        
        if (closeButton.hasAttribute('data-url')) {
            closeButton.innerHTML = 'Zobacz dopasowanych specjalistów';
            const parentDiv = document.createElement("div");
            parentDiv.classList.add('box', 'ten', 'padding_small');
            parentDiv.appendChild(closeButton)
            html += parentDiv.outerHTML;
        }

        this.modalBodyTarget.innerHTML = html;
        this.modalBodyTarget.style.opacity = 1;
    }

    updateElements() {
        this.elementToUpdateTargets.forEach((element) => { 
            this.updateElement(element)
        })
    }

    async updateElement(element) {
        const response = await fetch(element.dataset.url)
        element.innerHTML = await response.text();
    }

    nextPage() {
        var nextPageDiv = this.activePageDiv.nextElementSibling;
        const checkedRadio = this.activePageDiv.querySelector('[data-jump]:checked')

        if (checkedRadio !== null && checkedRadio.dataset.jump > 0) {
            const formSteps = document.querySelectorAll('.form_step');
            if (nextPageDiv !== null) {
                $(nextPageDiv).find('select').val(null).trigger('change');
            }
            nextPageDiv = formSteps[checkedRadio.dataset.jump - 1];
        }

        if (nextPageDiv !== null) {
            // if (nextPageDiv.querySelector('.select2entity') !== null) {
            //     $('script[src="' + '/bundles/tetranzselect2entity/js/select2entity.js' + '"]').remove();
            //     $('<script>').attr('src', '/bundles/tetranzselect2entity/js/select2entity.js').appendTo('head');
            // }

            if (nextPageDiv.dataset.modalSize === 'bigger') {
                this.modalTarget.querySelector('.universal_modal_box').classList.add('bigger_content_modal');
            } else {
                this.modalTarget.querySelector('.universal_modal_box').classList.remove('bigger_content_modal');
            }
            
            if (this.pageHtmlValidation()) {
                this.activePageDiv.classList.remove('active');
                nextPageDiv.classList.add('active');
                
                this.switchNavigationStep(nextPageDiv);
                this.activePageDiv = nextPageDiv;

                // $(nextPageDiv).find('.select2entity[data-autostart="true"]').select2entity();

                if (this.activeNavigationElement !== null) {
                    const nextNavigationElement = this.activeNavigationElement.nextElementSibling;
                    this.setActiveNavigationElement(nextNavigationElement)
                }
                
            }    

        }
    }

    prevPage() {
        var prevPageDiv = this.activePageDiv.previousElementSibling;
        const checkedRadio = document.querySelector('[data-jump="'+this.activePageDiv.dataset.step+'"]:checked')

        if (checkedRadio !== null) {
            prevPageDiv = checkedRadio.closest(".form_step")
        }

        if (prevPageDiv !== null) {
            if (prevPageDiv.dataset.modalSize === 'bigger') {
                this.modalTarget.querySelector('.universal_modal_box').classList.add('bigger_content_modal');
            } else {
                this.modalTarget.querySelector('.universal_modal_box').classList.remove('bigger_content_modal');
            }

            this.activePageDiv.classList.remove('active');
            prevPageDiv.classList.add('active');
            this.switchNavigationStep(prevPageDiv);
            this.activePageDiv = prevPageDiv;

            if (this.activeNavigationElement !== null) {
                const prevNavigationElement = this.activeNavigationElement.previousElementSibling;
                this.setActiveNavigationElement(prevNavigationElement)
            }
        }
    }

    pageHtmlValidation() {
        const inputElements = this.activePageDiv.querySelectorAll("input, select, checkbox, textarea")
        let result = true;
        inputElements.forEach(function(input) {
            if (!input.reportValidity()) {
                result = false
            }
        })
        
        return result;
    }   

    setActiveNavigationElement(element) {
        if (this.activeNavigationElement !== null) {
            this.activeNavigationElement.classList.remove('active');
        }
        
        element.classList.add('active');
        this.activeNavigationElement = element;
    }

    async enableDatePickers() {
        flatpickr('.time_picker', {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            locale: Polish,
            time_24hr: true
        })

        flatpickr('.date_picker', {
            dateFormat: "d.m.Y",
            locale: Polish
        });

        flatpickr('.datetime_picker', {
            enableTime: true,
            dateFormat: "d.m.Y H:i",
            minDate: "today",
            locale: Polish,
            time_24hr: true
        });

        this.enableCartDatePicker()
    }

    async enableCartDatePicker() {
        if (this.hasIsCartValue && this.isCartValue === true) {
            var calendarData = await $.ajax('/api/order/get-available-dates/' + this.cartServiceIdValue);
            const dateInput = document.getElementById('add_to_cart_form_date');
            dateInput.value = '';
            
            flatpickr('.cart_date_picker', {
                dateFormat: "Y-m-d",
                minDate: new Date(),
                maxDate: new Date().fp_incr(calendarData.availableDays),
                inline: true,
                locale: Polish,
                enable: Object.keys(calendarData.availableDates),
                onChange: function(selectedDates, dateStr, instance) {
                    const hoursDiv = document.querySelector('.card_date_picker_hours');
                    dateInput.value = '';
                    hoursDiv.innerHTML = '';

                    calendarData.availableDates[dateStr].forEach(timeSlot => {
                        let button = document.createElement("button"); 
                        button.innerHTML = timeSlot
                        button.classList.add('button_main', 'cart_time_button', 'splide__slide');
                        button.addEventListener('click', function(e) {
                            e.preventDefault();
                            dateInput.value = dateStr + ' ' + e.target.innerHTML;
                            document.querySelectorAll('.cart_time_button').forEach(otherButton => {
                                otherButton.classList.remove('button_checked_time');
                            })
                            button.classList.add('button_checked_time');
                          
                        })
                        hoursDiv.appendChild(button);
                    })

                    new Splide('.splide', {
                        type: 'slide',
                        perPage: 3,
                        pagination: false
                    }).mount();
                },
            });
        }
    }

    async returnToPreviousRegistrationStep(event) {
        try {
            let content = await $.ajax(event.target.dataset.url);
            this.modalBodyTarget.style.opacity = 0.5;
            this.modalBodyTarget.innerHTML = content;
            this.modalBodyTarget.style.opacity = 1;
            await this.modalFormStepController.getNavigation()
        } catch (e) {
            this.modalBodyTarget.innerHTML = e.responseText;
        }
    } 

    get modalFormStepController() {
        return this.application.getControllerForElementAndIdentifier(document.getElementById('registration_navigation'), 'modal-form-step')
    }

    get calendarController() {
        return this.application.getControllerForElementAndIdentifier(document.getElementById('therapist_calendar'), 'calendar')
    }

    checkFrameButton(event) {
        const input = event.target
        if (input.checked) {
            if (input.getAttribute("type") == 'radio') {
                input.parentNode.parentNode.querySelectorAll('.checkboxes_frame').forEach(input => {
                    input.classList.remove('checked');
                });
            }
            
    
            input.parentNode.classList.add('checked');
        } else {
            if (input.getAttribute("type") == 'checkbox') {
                input.parentNode.classList.remove('checked');
            }
        }
    }

    switchNavigationStep(div) {
        const navigation = document.querySelector('.modal_form_navigation');
        if (navigation !== null) {
            navigation.querySelectorAll('.modal_navigation_step').forEach(li => {
                if (li.dataset.step === div.dataset.step) {
                    li.innerHTML = '<strong>'+li.dataset.step+'</strong>'
                } else {
                    li.innerHTML = li.dataset.step
                }
            })
        }
    }

    toggleFields(event) {
        const eventTarget = event.target.tagName === 'SELECT' ? event.target.options[event.target.selectedIndex] : event.target;
        const targetField = eventTarget.dataset.toggleField;
        const targetElement = eventTarget.dataset.element;

        const targets = document.querySelectorAll('[data-target-field="' + targetField + '"]');
        targets.forEach(element => {
            element.style.display = 'none'
            
            element.querySelectorAll("input").forEach(input => {
                input.checked = false
                input.parentNode.classList.remove('checked')
            })
        });
     
        const target = document.querySelector('[data-target-field="' + targetField + '"][data-element="' + targetElement + '"]');
        
        if (eventTarget.type === 'checkbox' && eventTarget.checked === true) {
            target.style.display = 'block';
        } else if (eventTarget.type !== 'checkbox') {
            target.style.display = 'block';
        }   
    }
}