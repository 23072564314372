import { Controller } from '@hotwired/stimulus';
import flatpickr from "flatpickr";
import { Polish } from "flatpickr/dist/l10n/pl.js"
import $ from 'jquery';

export default class extends Controller {
    static targets = ['calendar', 'currentDate', 'reservations'];
    static values = {
        calendarUrl: String,
        reservationsUrl: String,
        currentDate: String,
        calendarId: Number
    }

    connect() {
        this.enableCalendarDatePicker();
    }

    async getCalendar() {
        const params = new URLSearchParams();
        this.calendarTarget.innerHTML = await $.post(this.calendarUrlValue, {
            date: this.currentDateValue,
            id: this.calendarIdValue
        });
    }

    async enableCalendarDatePicker() {
        flatpickr('.calendar_date_picker', {
            dateFormat: "Y-m-d",
            inline: true,
            locale: Polish,
            onChange: (selectedDates, dateStr, instance) => {
                this.currentDateValue = dateStr;
            },
        });
    }

    currentDateValueChanged() {
        this.currentDateTarget.innerHTML = this.currentDateValue.split("-").reverse().join(".");
        this.getCalendar();
        this.showCalendar(null);
    }

    changeDate(event) {
        const element = event.currentTarget
        const target = event.target
        
        if (!target.classList.contains('toggle_options')) {
            const fp = document.querySelector(".calendar_date_picker")._flatpickr;
            fp.setDate(new Date(element.dataset.datestr), true)
        }
    }

    showReservations(event) {
        event.preventDefault()
        document.getElementById('therapist_calendar_container').style.display = 'none';
        document.getElementById('therapist_reservations_container').style.display = 'block';
        this.getReservations()
    }

    showCalendar(event) {
        if (event !== null ) {
            event.preventDefault()
        }
    
        document.getElementById('therapist_calendar_container').style.display = 'block';
        document.getElementById('therapist_reservations_container').style.display = 'none';
    }

    async getReservations() {
        this.reservationsTarget.innerHTML = await $.get(this.reservationsUrlValue);
    }
}