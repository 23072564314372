import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['formBody'];
    static values = {
        formUrl: String,
    }

    async submitForm(event) {
        event.preventDefault();
        const $form = $(this.formBodyTarget).find('form');
        var formData = new FormData($form[0]);
        
        try {
            await $.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: formData,
                processData: false,
                contentType: false,
            });

            window.location.reload();
            
        } catch (e) {
            console.log(e.responseText)
            this.formBodyTarget.innerHTML = e.responseText;
        }
    }
}