import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['navigationBody'];
    static values = {
        navigationUrl: String,
    }
    
    connect() {
        this.getNavigation()
    }

    async getNavigation() {
        this.navigationBodyTarget.innerHTML = await $.ajax(this.navigationUrlValue);
    }
}