import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['formBody'];
    static values = {
        addUrl: String,
        thanksUrl: String
    }

    connect() {
        this.openForm();
    }

    async openForm() {
        this.formBodyTarget.innerHTML = await $.ajax(this.addUrlValue);
    }

    async submitForm(event) {
        event.preventDefault();
        const $form = $(this.formBodyTarget).find('form');
        var formData = new FormData($form[0]);
        
        try {
            await $.ajax({
                url: this.addUrlValue,
                method: $form.prop('method'),
                data: formData,
                processData: false,
                contentType: false,
            });

            this.newContent()
            
        } catch (e) {
            console.log(e.responseText)
            this.formBodyTarget.innerHTML = e.responseText;
        }
    }

    async newContent() {
        this.formBodyTarget.style.opacity = .5;
        const response = await fetch(this.thanksUrlValue);
        this.formBodyTarget.innerHTML = await response.text();
        this.formBodyTarget.style.opacity = 1;
    }
}