import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static values = {
        confirmUrl: String
    }

    async confirm() {
        let modals = document.querySelectorAll('.universal_modal');
        
        if (this.hasConfirmUrlValue == true) {
            try {
                await $.ajax({
                    url: this.confirmUrlValue,
                });
    
                console.log(window.location)
                window.location = window.location
            } catch (e) {
                this.modalBodyTarget.innerHTML = e.responseText;
            }
        }
        
        modals.forEach(modal => {
            modal.classList.remove('showing');
        });
    }

    abort() {
        window.location.reload();
    }
}