import { Controller } from 'stimulus'
import Swal from 'sweetalert2'

export default class extends Controller {

    static targets = [ 'fields', 'field', 'addButton' ]
    static values = {
        prototype: String,
        maxItems: Number,
        itemsCount: Number,
    }

    connect () {
        this.index = this.itemsCountValue = this.fieldsTargets.length
    }

    addItem(event, removeDeleteButton = false) {
        if (event !== null) {
            event.preventDefault()
        }
        
        let prototype = JSON.parse(this.prototypeValue)
        if (removeDeleteButton === true) {
            prototype = prototype.replace(/<button.((.|\n)*)>((.|\n)*)<\/button>/ig,'');
        }

        const newField = prototype.replace(/__name__/g, this.index)

        this.fieldsTarget.insertAdjacentHTML('beforeend', newField)
        this.index++
        this.itemsCountValue++

        if (document.querySelectorAll('.time_picker, .date_picker, .datetime_picker').length > 0) {
            this.enableDatePickers();
        }
    }

    removeItem(event) {
        event.preventDefault()
        this.fieldTargets.forEach(element => {
            console.log(element, event.target)
            if (element.contains(event.target)) {
                element.remove()
                this.itemsCountValue--
            }
        })
    }

    ajaxDeleteItem(event) {
        event.preventDefault()
        Swal.fire({
            title: 'Usunąć?',
            text: "Nie można cofnąć tej akcji",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DC3545',
            cancelButtonColor: '#666666',
            confirmButtonText: 'Tak, usuń',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Usunięto!',
                    text: 'Element został usunięty.',
                    icon: 'success',
                    showCloseButton: false,
                    showCancelButton: false,
                    showConfirmButton: false
                    })
                
                const response = fetch(event.target.dataset.url);
                event.target.parentNode.remove();
            }
          })
    }

    itemsCountValueChanged() {
        if (false === this.hasAddButtonTarget || 0 === this.maxItemsValue) {
            return
        }
        const maxItemsReached = this.itemsCountValue >= this.maxItemsValue
        this.addButtonTarget.classList.toggle('hidden', maxItemsReached)
    }

    async enableDatePickers() {
        flatpickr('.time_picker', {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true
        })

        flatpickr('.date_picker', {
            dateFormat: "d.m.Y"
        });

        flatpickr('.datetime_picker', {
            enableTime: true,
            dateFormat: "d.m.Y H:i",
            minDate: "today",
            time_24hr: true
        });

        this.enableCartDatePicker()
    }
}