import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['element'];
    static values = {
        url: String,
        therapistId: Number
    }

    connect() {
        this.getFreeSlot();
    }

    async getFreeSlot() {
        this.elementTarget.innerHTML = await $.get(this.urlValue);
    }
}