/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './styles/app.css';

// start the Stimulus application
import './bootstrap';
import './css/select2.min.css';
import './js/select2.min.js';
import 'tom-select/dist/css/tom-select.bootstrap5.css';
import 'bootstrap-icons/font/bootstrap-icons.css';